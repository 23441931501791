(function () {

    'use strict';

    var HomeTitle = {
        $title: $('.hero h1'),
        init: function () {
            var self = this;
            setTimeout(function () {
                self.bindEvents();
            }, 3000);
        },
        bindEvents: function () {
            var self = this;
            $(document).one('mousemove', function () {
                self.$title.addClass('fadeout');
            });
        }
    };
    if (matchMedia('(pointer:fine)').matches) {
        HomeTitle.init();
    }

})(window);