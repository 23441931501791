(function ($) {

    "use strict";

    var FadeImg = function (el) {
        this.$el = $(el);
        this.init();
    };

    FadeImg.prototype = {
        init: function () {
            this.loadImage();
        },
        loadImage: function () {
            var self = this,
                image = new Image,
                bg = this.$el.css('background-image'),
                src = bg.replace('url(','').replace(')','').replace(/\"/gi, "");

            image.onload = function () {
                self.$el.addClass('bg-loaded');
            };
            image.src = src;
        }
    };

    /* Wrapper de jQuery */
    $.fn.FadeImage = function () {
        return this.each(function () {
            var data = $(this).data('lb.lbfadeimg');
            if ( ! data) {
                $(this).data(
                    'lb.lbfadeimg', // namespace LB
                    (data = new FadeImg(this))
                );
            }
        });
    };

}(jQuery));