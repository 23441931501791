(function () {

    'use strict';

    var HomeGif = {
        $window: $(window),
        $img: $('.home-info-animation'),
        imageLoaded: false,
        waitForLoad: false,
        init: function () {
            this.src = this.$img.data('gif') + '?' + Math.random();
            this.loadImage();
            this.bindEvents();
            this.checkOffset();
        },
        loadImage: function () {
            var self = this,
                img = new Image();
            img.onload = function () {
                self.imageLoaded = true;
                if (self.waitForLoad) {
                    self.showImage();
                }
            }
            img.src = this.src;
        },
        bindEvents: function () {
            var self = this;
            this.$window.on('scroll', $.proxy(this.checkOffset, this));
        },
        checkOffset: function () {
            if (this.$window.scrollTop() + this.$window.height() >= this.$img.offset().top) {
                this.showImage();
            }
        },
        showImage: function () {
            if (!this.imageLoaded) {
                return this.waitForLoad = true;
            }
            this.$window.off('scroll', $.proxy(this.checkOffset, this));
            this.$img.attr('src', '')
                .attr('src', this.src);
        }
    };
    HomeGif.init();

})(window);